<template>
  <div class="container">
    <Category title="游戏">
      <template #default="atguigu">
        <ul>
          <li
            v-for="(g,index) in atguigu.games"
            :key="index"
          >
            {{ g }}
          </li>
        </ul>
      </template>
    </Category>
    <button @click="liusaosao">刘骚骚</button>

    <Category title="游戏">
      <template slot-scope="{games}">
        <ol>
          <li
            v-for="(g,index) in games"
            :key="index"
            style="color:red"
          >
            {{ g }}
          </li>
        </ol>
      </template>
    </Category>

    <Category title="游戏">
      <template slot-scope="{games}">
        <h4
          v-for="(g,index) in games"
          :key="index"
        >
          {{ g }}
        </h4>
      </template>
    </Category>
  </div>
</template>

<script>
    import Category from './components/Category';
    export default {
        name:'App',
        components:{Category},
       methods:{
         liusaosao(){
           alert('智障儿童欢乐多')
         }
       }
    }
</script>

<style scoped>
    .container,.foot{
        display: flex;
        justify-content: space-around;
    }
    h4{
        text-align: center;
    }
</style>
